.options_container {
    margin-bottom: 50px;
    /*for floating footer*/
}

.options {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.options_button {
    width: 100%;
}

.footer_container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin: 10px;
}

.footer_buttons_container {
    display: flex;
}

/*
daniel.kwok 26/4/2022
For exporting as PDF
*/
html {
    -webkit-print-color-adjust: exact;
}