.container {
    position: relative;
    /* background-color: red; */
}

.background {
    width: 100%;
    position: absolute;
    top: 0px;
}

.progress {
    position: absolute;
    vertical-align: middle;
    height: 10px;
    border-radius: 10px;
    top: 33%;
}

.bulb {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    position: absolute;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}